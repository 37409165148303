/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import style from './TweetInput.module.css';
import { useState, useRef } from 'react';
// import TextareaAutosize from 'react-textarea-autosize';
import Picker from 'emoji-picker-react';
import { IconMoodSmile, IconPlus, IconMinus, IconPhoto } from '@tabler/icons';
import retweet from './images/retweet.png';
import { TextareaAutosize, useTheme } from '@mui/material';
import PreviewUploadImg from './PreviewUploadImg/PreviewUploadImg';

const tweetCharLimit = 280;

const TweetInput = ({
    val,
    index,
    length,
    addInput,
    removeInput,
    handleOnCahange,
    handleImgOnChange,
    handleImojiClick,
    setShowRetweet,
    showRetweet,
    setRetweetNumber,
    setRetweetDelay
}) => {
    const imgInputRef = useRef();
    const [showImojiPicker, setShowImojiPicker] = useState(false);
    const theme = useTheme();

    return (
        <div>
            <TextareaAutosize
                minRows={index === 0 ? 6 : 4}
                placeholder={
                    index === 0
                        ? `What would you like to share?${length === 1 ? `\nTips: click on [+] button to add thread` : ''}`
                        : `Tweet${index + 1}`
                }
                value={val.text}
                onChange={(e) => handleOnCahange(index, e.target.value)}
                style={{
                    fontFamily: 'Roboto',
                    background: 'transparent',
                    width: '100%',
                    padding: '.2em .6em',
                    border: `1px solid ${theme.palette.mode === 'dark' ? theme.palette.grey[500] : theme.palette.grey[200]}`,
                    borderRadius: '7px',
                    fontSize: '15px',
                    color: theme.palette.grey[600],
                    '--placeholder-color': 'red'
                }}
            />

            {val.img && <PreviewUploadImg val={val} index={index} />}

            <div className={style.utilities}>
                <p className={val.text.length <= tweetCharLimit ? style.charCount : `${style.charCount} ${style.error}`}>
                    {val.text.length}/{tweetCharLimit}
                </p>

                <div className={style.right}>
                    <img
                        onClick={() => {
                            setShowRetweet(!showRetweet);
                            setRetweetNumber(0);
                            setRetweetDelay(2);
                        }}
                        style={{ heigh: '20px', width: '20px', cursor: 'pointer' }}
                        src={retweet}
                        alt=""
                    />

                    <button
                        style={{ cursor: 'pointer' }}
                        type="button"
                        className={style.tool_btn}
                        title="Image Upload"
                        onClick={() => imgInputRef.current.click()}
                    >
                        {' '}
                        <IconPhoto />{' '}
                    </button>
                    <button
                        style={{ cursor: 'pointer' }}
                        type="button"
                        className={style.tool_btn}
                        title="Emoji"
                        onClick={() => setShowImojiPicker((val) => !val)}
                    >
                        <IconMoodSmile />
                    </button>

                    {length > 1 && (
                        <button style={{ cursor: 'pointer' }} type="button" className={style.remove_btn} onClick={() => removeInput(index)}>
                            <IconMinus size={19} />
                        </button>
                    )}
                    <button style={{ cursor: 'pointer' }} type="button" className={style.add_btn} onClick={() => addInput(index)}>
                        <IconPlus size={19} />
                    </button>
                </div>
                {showImojiPicker && (
                    <Picker
                        onEmojiClick={(event, emojiObject) => handleImojiClick(emojiObject, index, setShowImojiPicker)}
                        pickerStyle={{
                            position: 'fixed',
                            bottom: '0',
                            left: '0',
                            zIndex: '999'
                        }}
                    />
                )}
            </div>

            {/* image unput */}
            <input
                type="file"
                accept="image/*"
                ref={imgInputRef}
                onChange={(e) => handleImgOnChange(index, e)}
                className={style.img_input}
            />
        </div>
    );
};

export default TweetInput;
