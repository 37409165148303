// assets
import {
    IconDashboard,
    IconDeviceAnalytics,
    IconCalendarStats,
    IconSettings,
    IconBrandTwitter,
    IconAdjustments,
    IconAward,
    IconBook2,
    IconBrandGravatar
} from '@tabler/icons';

// constant
const icons = {
    IconDashboard,
    IconDeviceAnalytics,
    IconCalendarStats,
    IconSettings,
    IconBrandTwitter,
    IconAdjustments,
    IconAward,
    IconBook2,
    IconBrandGravatar
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const group1 = {
    id: 'dashboard',
    type: 'group',
    children: [
        // {
        //     id: 'dashboard',
        //     title: 'Dashboard',
        //     type: 'item',
        //     url: '/dashboard',
        //     icon: icons.IconDashboard,
        //     breadcrumbs: false
        // },
        {
            id: 'dashboard',
            title: 'Dashboard',
            type: 'item',
            url: '/dashboard',
            icon: icons.IconDeviceAnalytics,
            breadcrumbs: false
        },
        {
            id: 'tweets',
            title: 'Tweet Library',
            type: 'item',
            url: '/tweets',
            icon: icons.IconBrandTwitter,
            breadcrumbs: false
        },
        {
            id: 'queue',
            title: 'Tweet Queue',
            type: 'item',
            url: '/queue',
            icon: icons.IconCalendarStats,
            breadcrumbs: false
        }
    ]
};

export default group1;
