/* eslint-disable camelcase */
/* eslint-disable prettier/prettier */
/* eslint-disable prefer-arrow-callback */
// routing
import Routes from 'routes';

// project imports
import Locales from 'ui-component/Locales';
import NavigationScroll from 'layout/NavigationScroll';
import RTLLayout from 'ui-component/RTLLayout';
import Snackbar from 'ui-component/extended/Snackbar';
import ThemeCustomization from 'themes';

import 'react-toastify/dist/ReactToastify.css';

// auth provider
import { FirebaseProvider as AuthProvider } from 'contexts/FirebaseContext';
import TweetProvider from 'contexts/TweetsyContext';
import axios from 'axios';
import TweetsyConfig from 'TweetsyConfig';
import { ReactSession } from 'react-client-session';

const updateUserData = () => {
    const user = ReactSession.get('dbuser');
    const userDataExpiration = ReactSession.get('userDataExpiration');
    const BASE_URL = TweetsyConfig.getNodeUrl();
    // console.log(process.env.REACT_APP_CREATE_TOKEN_SECRET, '1234');
    // create request body
    const body = {
        UID: user?.user,
        password: 'encryptedPassword-Iq9!7MP7I3Ng',
        email: user?.user_email
    };
    let haveToUpdateSession = false;

    if (!userDataExpiration) {
        haveToUpdateSession = true;
    }

    if (userDataExpiration && new Date().getTime() > userDataExpiration) {
        haveToUpdateSession = true;
    }

    console.log(haveToUpdateSession, '12345');

    if (haveToUpdateSession) {
        console.log(haveToUpdateSession, '12345 again');
        axios
            .post(`${BASE_URL}auth/email-password-login`, body)
            .then(({ data }) => {
                console.log('12345 .then executed');
                const { displayName, screen_api, status, userExportCredit } = data.user;
                const twitterObj = { uid: body.UID, displayName, email: body.email, screenName: screen_api, status, userExportCredit };
                ReactSession.set('twitter', twitterObj);
                ReactSession.set('token', data.token);
                ReactSession.set('subscribe', data?.stripe || {});
                ReactSession.set('dbuser', { ...data.user, screenName: screen_api });
                ReactSession.set('dbloginuser', data.loggedin_user);
                const expirationTime = new Date().getTime() + 30 * 60 * 1000; // 30 minutes in milliseconds
                ReactSession.set('userDataExpiration', expirationTime);
                window.location.reload(false);
            })
            .catch((err) => {
                console.log(err, '12345');
            });
    }
};

updateUserData();

const App = () => (
    <ThemeCustomization>
        <RTLLayout>
            <Locales>
                <NavigationScroll>
                    <AuthProvider>
                        <TweetProvider>
                            <>
                                <Routes />
                                <Snackbar />
                            </>
                        </TweetProvider>
                    </AuthProvider>
                </NavigationScroll>
            </Locales>
        </RTLLayout>
    </ThemeCustomization>
);

export default App;
