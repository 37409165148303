// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
    IconDashboard,
    IconDeviceAnalytics,
    IconCalendarStats,
    IconSettings,
    IconBrandTwitter,
    IconAdjustments,
    IconAward,
    IconBook2,
    IconBrandGravatar
} from '@tabler/icons';

// constant
const icons = {
    IconDashboard,
    IconDeviceAnalytics,
    IconCalendarStats,
    IconSettings,
    IconBrandTwitter,
    IconAdjustments,
    IconAward,
    IconBook2,
    IconBrandGravatar
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const group3 = {
    id: 'tweetsy',
    // title: <FormattedMessage id="tweetsy" />,
    type: 'group',
    // showTitle: true,
    children: [
        {
            id: 'subscription',
            title: <FormattedMessage id="subscription" />,
            type: 'item',
            url: '/subscription',
            icon: icons.IconAward,
            breadcrumbs: false
        },
        {
            id: 'settings',
            title: <FormattedMessage id="settings" />,
            type: 'item',
            url: '/settings',
            icon: icons.IconSettings,
            breadcrumbs: false
        }
    ]
};

export default group3;
