// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
    IconSearch,
    IconDashboard,
    IconDeviceAnalytics,
    IconCalendarStats,
    IconSettings,
    IconBrandTwitter,
    IconAdjustments,
    IconAward,
    IconBook2,
    IconBrandGravatar,
    IconMessage
} from '@tabler/icons';

// constant
const icons = {
    IconDashboard,
    IconDeviceAnalytics,
    IconCalendarStats,
    IconSettings,
    IconBrandTwitter,
    IconAdjustments,
    IconAward,
    IconBook2,
    IconBrandGravatar,
    IconMessage
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const tweetsy = {
    id: 'engagement',
    title: 'Engagement',
    type: 'group',
    showTitle: true,
    children: [
        // {
        //     id: 'welcome',
        //     title: 'Welcome Message',
        //     type: 'item',
        //     url: '/welcome',
        //     icon: icons.IconMessage,
        //     breadcrumbs: false
        // },
        {
            id: 'engagement',
            title: 'By Account',
            type: 'item',
            url: '/engagement',
            icon: icons.IconDeviceAnalytics,
            breadcrumbs: false
        },
        {
            id: 'niche-engagement',
            title: 'By Niche',
            type: 'item',
            url: '/niche-engagement',
            icon: icons.IconBook2,
            breadcrumbs: false,
            chip: {
                // label: <FormattedMessage id=" New " />,
                label: ' New ',
                color: 'primary',
                // color: 'grey',
                // avatar: <FormattedMessage id="n" />,
                size: 'small'
            }
        }
        // {
        //     id: 'keyword',
        //     title: 'By Keyword',
        //     type: 'item',
        //     url: '/keyword',
        //     icon: IconSearch,
        //     breadcrumbs: false
        // }
    ]
};

export default tweetsy;
