import { Outlet } from 'react-router-dom';
// material-ui
import Box from '@mui/material/Box';
// import LinearProgress from '@mui/material/LinearProgress';
// import CircularProgress from '@mui/material/CircularProgress';
import useAuth from 'hooks/useAuth';

export default function NotFoundLayout() {
    const { isLoading } = useAuth();
    if (isLoading) {
        return <div />;
    }
    return (
        <Box>
            <Outlet />
        </Box>
    );
}
