// const BASE_URL_NEW = 'https://www.tweetsy-backend.com/';
const NODE_BASE_URL_LOCAL = 'http://localhost:5000/';
// const NODE_BASE_URL_LOCAL = 'https://tweetsy-backend.onrender.com/';  //new server for tweetsy at render (added by Fozlol_dev)
// const NODE_BASE_URL = 'https://node.tweetsy-backend.com/';
const NODE_BASE_URL = 'https://tweetsy-backend.onrender.com/';

class TweetsyConfig {
    // eslint-disable-next-line class-methods-use-this
    geturl() {
        // changing the url for all at a time
        return 'BASE_URL_NEW';
    }

    // eslint-disable-next-line class-methods-use-this
    geturlnew() {
        return 'BASE_URL_NEW';
    }

    // eslint-disable-next-line class-methods-use-this
    getNodeUrl() {
        // return NODE_BASE_URL;
        return process.env.NODE_ENV === 'development' ? NODE_BASE_URL_LOCAL : NODE_BASE_URL;
    }

    tweetPerPage = 30;
}

export default new TweetsyConfig();
