/* eslint-disable prettier/prettier */
import { Navigate, useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import LoginRoutes from './LoginRoutes';
// import AuthenticationRoutes from './AuthenticationRoutes';
import useAuth from 'hooks/useAuth';
import { DASHBOARD_PATH } from 'config';
// import AdminRoutes from './AdminRoutes';
import NotFoundRoute from './NotFoundRoute';

const expiredAuthRoutes = [{ path: '/subscription', element: <Navigate to="/unauthenticated" replace /> }];
let routeStr = '/';
export default function ThemeRoutes() {
    const { isTwitterAuth, isLoading, isExpired } = useAuth();
    if (isTwitterAuth) routeStr = '/unauthenticated';
    else if (isExpired) routeStr = '/expired';
    const authCommonRoutes = [
        { path: '/', element: <Navigate to={routeStr} replace /> },
        { path: '/tweets', element: <Navigate to={routeStr} replace /> },
        { path: '/queue', element: <Navigate to={routeStr} replace /> },
        { path: '/engagement', element: <Navigate to={routeStr} replace /> },
        { path: '/niche-engagement', element: <Navigate to={routeStr} replace /> },
        { path: '/keyword', element: <Navigate to={routeStr} replace /> },
        { path: '/analytics', element: <Navigate to={routeStr} replace /> },
        LoginRoutes,
        MainRoutes,
        // AdminRoutes,
        NotFoundRoute
    ];

    // disabling auth sample reoute!
    const routes = [
        { path: '/', element: <Navigate to={DASHBOARD_PATH} replace /> },
        LoginRoutes,
        MainRoutes,
        // AdminRoutes,
        NotFoundRoute
    ];
    let allRoutes = [];
    if (isLoading) {
        allRoutes = [LoginRoutes, NotFoundRoute];
    } else if (isTwitterAuth) {
        allRoutes = [...expiredAuthRoutes, ...authCommonRoutes];
    } else if (isExpired) {
        allRoutes = [...authCommonRoutes];
    } else {
        allRoutes = routes;
    }
    return useRoutes(allRoutes);
}
