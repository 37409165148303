import useAuth from 'hooks/useAuth';
// import admin from './admin';
import group1 from './group1';
import group3 from './group3';
import tweetsy from './tweetsy';

// export default function AllMenus() {
//     const { dbUser } = useAuth();
//     const items = [group1, tweetsy, group3];
//     if (dbUser?.role && ['SUPER_ADMIN', 'ADMIN'].includes(dbUser?.role)) {
//         items.unshift(admin);
//     }
//     return { items };
// }
export default { items: [group1, tweetsy, group3] };
