/* eslint-disable camelcase */
/* eslint-disable no-unreachable */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable object-shorthand */
/* eslint-disable no-empty */
/* eslint-disable no-alert */
/* eslint-disable spaced-comment */
/* eslint-disable arrow-body-style */
/* eslint-disable consistent-return */
/* eslint-disable no-underscore-dangle */
/* eslint-disable prettier/prettier */
import PropTypes from 'prop-types';
import { useState, createContext, useEffect, useReducer } from 'react';

// third-party
// import firebase from 'firebase/compat/app';
// import 'firebase/compat/auth';
import { initializeApp } from 'firebase/app';
import {
    createUserWithEmailAndPassword,
    getAuth,
    GoogleAuthProvider,
    onAuthStateChanged,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signInWithPopup,
    signOut,
    TwitterAuthProvider
} from 'firebase/auth';
import { ReactSession } from 'react-client-session';

// action - state management
import { LOGIN, LOGOUT } from 'store/actions';
import accountReducer from 'store/accountReducer';

// project imports
import Loader from 'ui-component/Loader';
import { FIREBASE_API, DASHBOARD_PATH, UNAUTHENTICATEDROUTES } from 'config';
import TweetsyConfig from 'TweetsyConfig';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, LinearProgress } from '@mui/material';

ReactSession.setStoreType('localStorage');

// firebase initialize
// if (!firebase.apps.length) {
//     firebase.initializeApp(FIREBASE_API);
// }
initializeApp(FIREBASE_API);

const auth = getAuth();
const twitterProvider = new TwitterAuthProvider();

// const
const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

// ==============================|| FIREBASE CONTEXT & PROVIDER ||============================== //

const FirebaseContext = createContext(null);

export const FirebaseProvider = ({ children }) => {
    const BASE_URL = TweetsyConfig.getNodeUrl();
    const navigate = useNavigate();
    const location = useLocation();
    const [state, dispatch] = useReducer(accountReducer, initialState);
    const [dbUser, setDbUser] = useState(ReactSession.get('dbuser') || {});
    const [dbLoginUser, setDbLoginUser] = useState(ReactSession.get('dbloginuser') || {});
    const [likedTweet, setLikedTweet] = useState([]);
    const [twitter, setTwitter] = useState(ReactSession.get('twitter') || {});
    const [repliedTweet, setRepliedTweet] = useState([]);
    const [skippedTweet, setSkippedTweet] = useState(ReactSession.get('skippedTweet') || []);
    const [isExpired, setIsExpired] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isTwitterAuth, setIsTwitterAuth] = useState();
    const [generalError, setGeneralError] = useState('');
    const [accessToken, setAccessToken] = useState(ReactSession.get('token') || '');
    const [subscribeInfo, setSubscribeInfo] = useState(ReactSession.get('subscribe') || {});

    const logout = () => {
        const logedOut = signOut(auth);
        ReactSession.set('twitter', {});
        ReactSession.set('dbuser', {});
        ReactSession.set('dbloginuser', {});
        ReactSession.set('token', '');
        ReactSession.set('subscribe', null);
        ReactSession.set('userDataExpiration', {});
        setAccessToken('');
        setDbUser({});
        setDbLoginUser({});
        setTwitter({});
        setSubscribeInfo({});
        localStorage.clear();
        sessionStorage.clear();
        return logedOut;
    };
    const getToken = async (uid) => {};

    // exfired count
    useEffect(() => {
        if (dbLoginUser?.end_date) {
            const endDateValue = new Date(dbLoginUser.end_date)?.valueOf?.();
            const currentDateValue = new Date().valueOf();
            const subsEndDate = subscribeInfo?.current_period_end;
            if (currentDateValue > endDateValue || (subsEndDate && subsEndDate < currentDateValue)) return setIsExpired(true);
            // eslint-disable-next-line no-else-return
            else setIsExpired(false);
        } else if (Object.keys(dbLoginUser || {}).length) {
            // logout();
        }
    }, [dbLoginUser]);

    // useEffect(() => {
    //     if (dbUser && !dbUser?.wishList?.length ) {
    //         navigate('/setup', { replace: true });
    //     }
    // }, [dbUser]);

    // Twitter signIn/signUp
    const twitterSignIn = () => {
        signInWithPopup(auth, twitterProvider)
            .then(async (result) => {
                const {
                    user: { uid },
                    _tokenResponse: { oauthAccessToken, oauthTokenSecret, displayName, email, screenName, photoUrl, idToken }
                } = result;

                await getToken(uid);
                const twitterObj = { uid, displayName, screenName, email, photoUrl, idToken };

                setTwitter(twitterObj);
                ReactSession.set('twitter', twitterObj);

                fetch(`${TweetsyConfig.getNodeUrl()}sessions/callback/${oauthAccessToken}/${oauthTokenSecret}/${uid}/${email}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${ReactSession.get('token') || 'none'}`
                    }
                })
                    .then((res) => res.json())
                    .then((data) => {
                        console.log({ data }, 'result');

                        console.log(
                            { dbuser: data.user, loginuser: data.login_user, skip: data.user.skippedTweet, token: data.token },
                            '12345678'
                        );

                        ReactSession.set('token', data.token);
                        setSubscribeInfo(data?.stripe || {});
                        ReactSession.set('dbuser', { ...data.user, screenName });
                        ReactSession.set('dbloginuser', data.login_user);
                        setDbUser(data.user);
                        setDbLoginUser(data.login_user);
                        const expirationTime = new Date().getTime() + 30 * 60 * 1000; // 30 minutes in milliseconds
                        ReactSession.set('userDataExpiration', expirationTime);
                        window.location.reload(false);
                    })
                    .catch((e) => {
                        console.log(e);
                    });
            })
            .catch((error) => {
                console.dir(error);
            });
    };

    useEffect(
        () =>
            onAuthStateChanged(auth, async (user) => {
                if (user) {
                    getToken(user?.uid);
                }
                if (user) {
                    dispatch({
                        type: LOGIN,
                        payload: {
                            isLoggedIn: true,
                            user: {
                                id: user.uid,
                                email: user.email,
                                name: user.displayName,
                                image: user.photoURL
                            }
                        }
                    });
                } else if (!isLoading) {
                    dispatch({
                        type: LOGOUT
                    });
                    await logout();
                    localStorage.clear();
                }
            }),
        []
    );
    useEffect(() => {
        if (twitter?.isAuthenticated) {
            setIsTwitterAuth(false);
        } else if (dbUser?.status !== 'Active') {
            setIsTwitterAuth(true);
        } else {
            setIsTwitterAuth(false);
        }
        if (!Object.keys(dbUser || {})?.length) {
            let url = '/login';
            if (UNAUTHENTICATEDROUTES?.includes?.(location.pathname)) {
                url = location.pathname;
            }
            navigate(url, { replace: true });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dbUser, twitter]);

    useEffect(() => {
        if (state?.user?.id && dbUser?._id && ReactSession.get('token')) {
            axios
                .get(`${TweetsyConfig.getNodeUrl()}likedtweets/${dbUser.user}`, {
                    headers: { Authorization: `Bearer ${ReactSession.get('token')}` }
                })
                .then((res) => {
                    const flatting = JSON.parse(JSON.stringify(res?.data?.flat?.() || []));
                    setLikedTweet(flatting.flat());
                })
                .catch(() => {});
            // get replyed
            axios
                .get(`${TweetsyConfig.getNodeUrl()}user/replydata/${dbUser?.user}`, {
                    headers: { Authorization: `Bearer ${ReactSession.get('token')}` }
                })
                .then((res) => {
                    setRepliedTweet(res?.data || []);
                })
                .catch(() => {});
        }
    }, [state, dbUser, ReactSession.get('token')]);

    // ==================================================
    // old code for old email based auth START; no need of those
    // ==================================================

    const firebaseEmailPasswordSignIn = ({ email, password }) => {
        // console.log({ email, password }, '1234');
        setIsLoading(true);
        // console.log({ email, password }, '1234');
        if (!password) {
            toast('Please enter your password', {
                autoClose: 2500,
                type: 'error'
            });
            return;
        }

        signInWithEmailAndPassword(auth, email, password)
            .then((result) => {
                const UID = result.user.uid;
                const body = {
                    UID,
                    password,
                    email
                };
                axios
                    .post(`${BASE_URL}auth/email-password-login`, body)
                    .then(({ data }) => {
                        const { displayName, screen_api, status, userExportCredit } = data.user;
                        const twitterObj = { uid: UID, displayName, email, screen_api, status, userExportCredit };
                        setTwitter(twitterObj);
                        ReactSession.set('twitter', twitterObj);
                        ReactSession.set('token', data.token);
                        ReactSession.set('subscribe', data?.stripe || {});
                        setSubscribeInfo(data?.stripe || {});
                        ReactSession.set('dbuser', data.user);
                        ReactSession.set('dbloginuser', data.loggedin_user);
                        setDbUser(data.user);
                        setDbLoginUser(data.loggedin_user);
                        const expirationTime = new Date().getTime() + 30 * 60 * 1000; // 30 minutes in milliseconds
                        ReactSession.set('userDataExpiration', expirationTime);
                        let redirect = DASHBOARD_PATH;
                        if (data.user?.status !== 'Active') redirect = '/unauthenticated';
                        // dbLoginUser?.end_date

                        if (!data?.loggedin_user?.end_date || new Date(data.loggedin_user?.end_date)?.valueOf() < Date.now())
                            redirect = '/subscription';
                        return navigate(redirect, { replace: true });
                    })
                    .catch(async (e) => {
                        toast(e?.response?.data?.message || e.message, {
                            autoClose: 2500,
                            type: 'error'
                        });
                        await logout();
                    })
                    .finally(() => {
                        setIsLoading(false);
                    });
            })
            .catch((e1) => {
                const { code, message } = e1;
                const msg = code === 'auth/user-not-found' || code === 'auth/wrong-password' ? `Credentials doesn't match` : message;
                toast(msg, {
                    autoClose: 2500,
                    type: 'error'
                });
                setIsLoading(false);
            });
    };

    const firebaseGoogleSignIn = () => {
        const provider = new GoogleAuthProvider();

        return signInWithPopup(provider);
    };

    const firebaseRegister = async (email, password) => createUserWithEmailAndPassword(email, password);
    const firebaseRegisterWithOTP = (values) => {
        setIsLoading(true);
        const { name, email, password, OTP } = values;
        console.log(values, '1234 - values at 363 firebase context');
        createUserWithEmailAndPassword(auth, email, password)
            .then(async (result) => {
                const UID = result.user.uid;
                const body = {
                    UID,
                    password,
                    email,
                    OTP
                };
                axios
                    .put(`${BASE_URL}auth/veriry-otp-with-loggedIn-user`, body)
                    .then(({ data }) => {
                        const twitterObj = { uid: UID, displayName: name, email };
                        setTwitter(twitterObj);
                        ReactSession.set('twitter', twitterObj);
                        ReactSession.set('token', data.token);
                        ReactSession.set('subscribe', data?.stripe || {});
                        setSubscribeInfo(data?.stripe || {});
                        ReactSession.set('dbuser', data.user);
                        ReactSession.set('dbloginuser', data.loggedin_user);
                        setDbUser(data.user);
                        setDbLoginUser(data.loggedin_user);
                        navigate('/unauthenticated');
                    })
                    .catch(async (eRR) => {
                        if (UID) {
                            await auth.currentUser.delete();
                        }
                        await logout();
                        setGeneralError(eRR.response?.data?.message || eRR.message);
                    })
                    .finally(() => setIsLoading(false));
            })
            .catch((error) => {
                let msg = 'Something wont wrong';
                if (error.code === 'auth/email-already-in-use') msg = 'User already register';
                setGeneralError(msg);
            })
            .finally(() => setIsLoading(false));
    };

    const resetPassword = async (email) => {
        await sendPasswordResetEmail(email);
    };

    const updateProfile = () => {};
    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return (
        <FirebaseContext.Provider
            value={{
                ...state,
                firebaseRegister,
                firebaseEmailPasswordSignIn,
                login: () => {},
                firebaseGoogleSignIn,
                logout,
                resetPassword,
                updateProfile,
                // TWEETSY CUSTOM FUNCTION
                // twitterAuthentication,
                twitterSignIn,
                dbUser,
                setDbUser,
                dbLoginUser,
                twitter,
                setTwitter,
                isExpired,
                isTwitterAuth,
                accessToken,
                setAccessToken,
                isLoading,
                generalError,
                setGeneralError,
                firebaseRegisterWithOTP,

                // fetchSubscribeFunc,
                subscribeInfo,
                setDbLoginUser,

                // liked tweet
                likedTweet,
                setLikedTweet,
                // replyed tweet
                repliedTweet,
                setRepliedTweet,
                // skipped niche tweet
                skippedTweet,
                setSkippedTweet,
                dispatch
            }}
        >
            {children}
            {(isLoading === undefined || isLoading === null || isLoading === true) && (
                <Box>
                    <LinearProgress />
                </Box>
            )}
            <ToastContainer position="bottom-right" autoClose={2000} />
        </FirebaseContext.Provider>
    );
};

FirebaseProvider.propTypes = {
    children: PropTypes.node
};

export default FirebaseContext;
