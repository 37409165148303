// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, Button, Typography } from '@mui/material';

// project imports
import LogoSection from '../LogoSection';
// import SearchSection from './SearchSection';
import MobileSection from './MobileSection';
import ProfileSection from './ProfileSection';
import { useDispatch, useSelector } from 'store';
import { openDrawer } from 'store/slices/menu';
import useAuth from 'hooks/useAuth';
// assets
import { IconMenu2, IconBrandTwitter } from '@tabler/icons';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = () => {
    const theme = useTheme();
    // const { twitterAuthentication, dbUser, user, twitter } = useAuth();
    const { twitterSignIn, dbUser, user, twitter } = useAuth();
    const dispatch = useDispatch();
    const { drawerOpen } = useSelector((state) => state.menu);

    return (
        <>
            {/* logo & toggler button */}
            <Box
                sx={{
                    width: 228,
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
                    <LogoSection />
                </Box>
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.mediumAvatar,
                        overflow: 'hidden',
                        transition: 'all .2s ease-in-out',
                        // background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
                        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
                        // color: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
                        color: theme.palette.mode === 'dark' ? theme.palette.primary.main : theme.palette.primary.dark,
                        '&:hover': {
                            background: theme.palette.mode === 'dark' ? theme.palette.primary.main : theme.palette.primary.dark,
                            color: theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.primary.light
                        }
                    }}
                    onClick={() => dispatch(openDrawer(!drawerOpen))}
                    color="inherit"
                >
                    <IconMenu2 stroke={1.5} size="1.3rem" />
                </Avatar>
            </Box>

            {/* header search */}
            {/* 🛠Modified by FoysalBN: remove searchbar from top */}
            {/* <SearchSection /> */}

            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ flexGrow: 1 }} />

            <Box sx={{ marginRight: 1, bgcolor: '#E3F2FD', padding: '2px 15px 2px 0', borderRadius: 5, display: 'flex' }}>
                <Box>
                    <Avatar
                        src={
                            dbUser?.profileIMG
                                ? dbUser?.profileIMG
                                : user?.image || 'https://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png'
                        }
                        sx={{
                            margin: '0 3px !important',
                            cursor: 'pointer'
                        }}
                    />
                </Box>
                <Box>
                    <Typography>{dbUser?.displayName}</Typography>
                    {dbUser?.screen_api || dbUser?.screenName || twitter?.screen_api || twitter?.screenName ? (
                        <Typography sx={{ fontSize: '12px' }}>
                            Username @{dbUser.screen_api || dbUser?.screenName || twitter?.screen_api || twitter?.screenName}
                        </Typography>
                    ) : (
                        ''
                    )}
                </Box>
            </Box>
            <Box sx={{ marginRight: 1 }}>
                <Button
                    variant="contained"
                    // eslint-disable-next-line no-unneeded-ternary
                    startIcon={<IconBrandTwitter />}
                    onClick={() => {
                        // if (twitter?.isAuthenticated || dbUser?.status === 'Active') return;
                        twitterSignIn();
                    }}
                >
                    {twitter?.isAuthenticated || dbUser?.status === 'Active' ? 'Authenticated' : 'Authenticationn'}
                </Button>
            </Box>
            <ProfileSection />

            {/* mobile header */}
            <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                <MobileSection />
            </Box>
        </>
    );
};

export default Header;
