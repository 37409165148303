import { Button, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import useTweets from 'hooks/useTweets';
import React from 'react';
import ReactModal from 'react-modal';
import { IconMinus } from '@tabler/icons';
import TweetInputs from 'views/tweetsy/scheduleTweet/TweetInputs';

const ScheduleModal = () => {
    const { isModalOpen, setIsModalOpen } = useTweets();
    const theme = useTheme();

    return (
        <ReactModal
            isOpen={isModalOpen}
            ariaHideApp={false}
            style={{
                overlay: {
                    background: 'none',
                    position: 'fixed',
                    top: 'none',
                    left: 'none',
                    bottom: '19px',
                    zIndex: '101',
                    right: '11px'
                },
                content: {
                    zIndex: '101',
                    position: 'fixed',
                    top: 'none',
                    left: 'none',
                    bottom: '19px',
                    right: '11px',
                    minHeight: '60vh',
                    maxHeight: 'calc(100vh - 110px)',
                    width: '500px',
                    maxWidth: 'calc(100% - 22px)',
                    // backgroundColor: 'white',
                    background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[50],
                    padding: '.7em 1.4em',
                    // boxShadow: '0 0 7px 1px #00000057',
                    border: 'none',
                    borderRadius: '13px',
                    filter: 'drop-shadow(0 0 .48rem rgba(0,0,0,.24))'
                    // -webkit-filter: drop-shadow(0 0 .48rem rgba(0,0,0,.24));
                }
            }}
        >
            {/* modal header */}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 2
                }}
            >
                <Typography variant="h4" sx={{ color: theme.palette.grey[900] }}>
                    Schedule Tweet
                </Typography>
                <button
                    type="button"
                    style={{
                        border: 'none',
                        padding: '4px 9px',
                        paddingTop: '9px',
                        borderRadius: '5px'
                    }}
                    onClick={() => setIsModalOpen(false)}
                >
                    <IconMinus size={15} stroke={3} style={{ padding: 0 }} />
                </button>
            </Box>
            {/* modal body */}

            <TweetInputs forModal />
        </ReactModal>
    );
};

export default ScheduleModal;
