import style from './PreviewUploadImg.module.css';
import useTweets from '../../../../hooks/useTweets';
import { IconX } from '@tabler/icons';

const PreviewUploadImg = ({ val, index }) => {
    const { setScheduleInputs } = useTweets();

    const deleteImg = () => {
        const { id, text } = val;

        setScheduleInputs((val1) => {
            const copy = [...val1];
            copy[index] = { id, text };

            return copy;
        });
    };

    return (
        <div className={style.img_ontainer}>
            <div className={style.img_wrapper}>
                <img src={val.img.url} alt="" />
                <IconX className={style.close_icon} onClick={deleteImg} />
            </div>
        </div>
    );
};

export default PreviewUploadImg;
