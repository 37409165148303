/* eslint-disable no-underscore-dangle */
import { useEffect, useRef, useState } from 'react';
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import useTweets from '../../../hooks/useTweets';
import TweetInput from './TweetInput';
import imageToBase64 from 'image-to-base64/browser';
import axios from 'axios';
import TweetsyConfig from 'TweetsyConfig';
import useAuth from 'hooks/useAuth';
import { ReactSession } from 'react-client-session';
import { toast } from 'react-toastify';

const TweetInputs = () => {
    const theme = useTheme();
    const { idMemorized, setIdMemorized, scheduleInputs, setScheduleInputs } = useTweets();
    const dateTimeRef = useRef();
    const { dbUser } = useAuth();
    const [isScheduling, setIsScheduling] = useState(false);

    const [timeDelay, setTimeDelay] = useState('none');
    const [retweetNumber, setRetweetNumber] = useState(0);
    const [retweetDelay, setRetweetDelay] = useState(2);
    const [showRetweet, setShowRetweet] = useState(false);
    const handleChange = (event) => {
        setTimeDelay(event.target.value);
    };

    const options = [
        '5 seconds',
        '10 seconds',
        '1 minute',
        '2 minutes',
        '3 minutes',
        '4 minutes',
        '5 minutes',
        '6 minutes',
        '7 minutes',
        '8 minutes',
        '9 minutes',
        '10 minutes'
    ];

    const handleScheduleSubmit = (e) => {
        e.preventDefault();
        if (!dateTimeRef.current) return;

        const currentMS = new Date().valueOf();
        const selectedDate = new Date(dateTimeRef.current.value).valueOf();

        if (selectedDate - currentMS < 60000) {
            toast('Selected time should be at Least 2 Minute Later than Present Time', {
                autoClose: 3000,
                type: 'warning'
            });

            return;
        }

        const timeDelayObject = {
            none: 0,
            '5 seconds': 5000,
            '10 seconds': 10000,
            '1 minute': 60000,
            '2 minutes': 120000,
            '3 minutes': 180000,
            '4 minutes': 240000,
            '5 minutes': 300000,
            '6 minutes': 360000,
            '7 minutes': 420000,
            '8 minutes': 480000,
            '9 minutes': 540000,
            '10 minutes': 600000
        };
        const timeDelayInMillisecond = timeDelayObject[timeDelay];
        console.log(timeDelayInMillisecond);

        const texts = [];
        scheduleInputs.forEach((item) => {
            if (!item.text) return;

            const { text, img } = item;

            const tweet = { text };
            // eslint-disable-next-line consistent-return
            if (!img) return texts.push(tweet);

            tweet.options = { media_data: img.base64 };
            // eslint-disable-next-line consistent-return
            return texts.push(tweet);
        });

        if (!texts.length) return;

        const dateTimeGMT = new Date(dateTimeRef.current.value).toGMTString();
        const dateTimeMS = new Date(dateTimeGMT).valueOf();

        const thread = { texts, dateTimeGMT, dateTimeMS, timeDelay: timeDelayInMillisecond, retweetDelay, retweetNumber };
        console.log(thread);

        setIsScheduling(true);
        axios
            .post(`${TweetsyConfig.getNodeUrl()}TweetThread/${dbUser._id}`, thread, {
                headers: { Authorization: `Bearer ${ReactSession.get('token')}` }
            })
            .then(() => {
                toast(`Tweet scheduled successfully.`, {
                    autoClose: 3000,
                    type: 'success'
                });
                setScheduleInputs([{ id: 0, text: '' }]);
                dateTimeRef.current.value = null;
            })
            .catch((err) => {
                toast('Something went wromg.! please try again.', {
                    autoClose: 3000,
                    type: 'error'
                });
            })
            .finally(() => {
                setIsScheduling(false);
            });
    };

    const addInput = (index) => {
        setScheduleInputs((val) => [...val.slice(0, index + 1), { id: idMemorized, text: '' }, ...val.slice(index + 1)]);
        setIdMemorized(idMemorized + 1);
    };
    // eslint-disable-next-line consistent-return
    const removeInput = (index) => {
        if (index === 0) return setScheduleInputs((val) => [...val.slice(1)]);
        setScheduleInputs((val) => [...val.slice(0, index), ...val.slice(index + 1)]);
    };

    const handleOnCahange = (index, text) => {
        setScheduleInputs((val) => {
            const copy = [...val];
            const changes = val[index];
            changes.text = text;
            copy[index] = changes;

            return copy;
        });
    };

    const handleImojiClick = (emojiObject, index, setShowImojiPicker) => {
        setScheduleInputs((val) => {
            const copy = [...val];
            const changes = val[index];
            changes.text += emojiObject.emoji;
            copy[index] = changes;

            return copy;
        });

        setShowImojiPicker(false);
    };

    const handleImgOnChange = (index, e) => {
        const img = e.target.files[0];
        if (!img) return;
        const url = URL.createObjectURL(img);

        imageToBase64(url) // Image URL
            .then((res) => {
                const base64 = res;
                setScheduleInputs((val) => {
                    const copy = [...val];
                    const changes = val[index];
                    changes.img = {
                        file: img,
                        base64,
                        url
                    };
                    copy[index] = changes;

                    return copy;
                });
            })
            .catch(() => {})
            // eslint-disable-next-line no-return-assign
            .finally(() => (e.target.value = null));
    };

    return (
        <Box
            sx={{
                background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[50],
                p: 0,
                borderRadius: 2
            }}
        >
            <form onSubmit={handleScheduleSubmit}>
                {/* All inputs */}
                {scheduleInputs.map((val, index) => (
                    <TweetInput
                        key={val.id}
                        length={scheduleInputs.length}
                        setShowRetweet={setShowRetweet}
                        showRetweet={showRetweet}
                        setRetweetNumber={setRetweetNumber}
                        setRetweetDelay={setRetweetDelay}
                        {...{
                            val,
                            index,
                            addInput,
                            removeInput,
                            handleOnCahange,
                            handleImgOnChange,
                            handleImojiClick
                        }}
                    />
                ))}

                <Grid container spacing={1}>
                    {/* time Input */}
                    <Grid item md={6} xs={12}>
                        <input
                            type="datetime-local"
                            ref={dateTimeRef}
                            required
                            style={{
                                fontFamily: 'Roboto',
                                fontSize: '16px',
                                border: `1px solid ${theme.palette.grey[500]}`,
                                padding: '8px 5px',
                                borderRadius: '5px',
                                color: theme.palette.grey[500],
                                background: 'transparent'
                            }}
                        />
                    </Grid>

                    <Grid item md={6} xs={12}>
                        <FormControl sx={{ margin: '0x', padding: '0px', width: '100%', background: 'transparent' }} size="small">
                            <InputLabel id="demo-select-small">Thread delay</InputLabel>
                            <Select
                                sx={{ margin: '0px', padding: '0px' }}
                                labelId="demo-select-small"
                                id="demo-select-small"
                                value={timeDelay}
                                label="Time Delay"
                                onChange={handleChange}
                            >
                                <MenuItem value="none">
                                    <em>None</em>
                                </MenuItem>
                                {options.map((option) => (
                                    <MenuItem value={option} key={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                                {/* <MenuItem value={10}>Ten</MenuItem>
                                <MenuItem value={20}>Twenty</MenuItem>
                                <MenuItem value={30}>Thirty</MenuItem> */}
                            </Select>
                        </FormControl>
                    </Grid>
                    {showRetweet && (
                        <Grid item md={6} xs={12}>
                            <FormControl sx={{ margin: '0x', padding: '0px', width: '100%', background: 'transparent' }} size="small">
                                <InputLabel id="demo-select-small">Retweet number</InputLabel>
                                <Select
                                    sx={{ margin: '0px', padding: '0px' }}
                                    labelId="demo-select-small"
                                    id="demo-select-small"
                                    value={retweetNumber}
                                    label="Retweet Number"
                                    onChange={(e) => {
                                        setRetweetNumber(e.target.value);
                                    }}
                                >
                                    <MenuItem value={0}>
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value={1}>1</MenuItem>
                                    <MenuItem value={2}>2</MenuItem>
                                    <MenuItem value={3}>3</MenuItem>
                                    <MenuItem value={4}>4</MenuItem>
                                    <MenuItem value={5}>5</MenuItem>
                                    <MenuItem value={6}>6</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    )}

                    {showRetweet && (
                        <Grid item md={6} xs={12}>
                            <FormControl sx={{ margin: '0x', padding: '0px', width: '100%', background: 'transparent' }} size="small">
                                <InputLabel id="demo-select-small">Retweet delay</InputLabel>
                                <Select
                                    sx={{ margin: '0px', padding: '0px' }}
                                    labelId="demo-select-small"
                                    id="demo-select-small"
                                    value={retweetDelay}
                                    label="Retweet Delay"
                                    onChange={(e) => setRetweetDelay(e.target.value)}
                                >
                                    <MenuItem value={2}>2 hours</MenuItem>
                                    {/* <MenuItem value={2}>2 hours</MenuItem> */}
                                    <MenuItem value={4}>4 hours</MenuItem>
                                    <MenuItem value={6}>6 hours</MenuItem>
                                    <MenuItem value={8}>8 hours</MenuItem>
                                    <MenuItem value={10}>10 hours</MenuItem>
                                    <MenuItem value={12}>12 hours</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    )}

                    {/* <Grid item md={6} xs={12}>
                        <TextareaAutosize
                            minRows={1.7}
                            placeholder="write your comment"
                            value="ok"
                            // onChange={(e) => handleOnCahange(index, e.target.value)}
                            style={{
                                fontFamily: 'Roboto',
                                background: 'transparent',
                                width: '100%',
                                padding: '.2em .6em',
                                border: `1px solid ${theme.palette.mode === 'dark' ? theme.palette.grey[500] : theme.palette.grey[200]}`,
                                borderRadius: '7px',
                                fontSize: '15px',
                                color: theme.palette.grey[600],
                                '--placeholder-color': 'red'
                            }}
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <FormControl sx={{ margin: '0x', padding: '0px', width: '100%', background: 'transparent' }} size="small">
                            <InputLabel id="demo-select-small">Select like difference</InputLabel>
                            <Select
                                sx={{ margin: '0px', padding: '0px' }}
                                labelId="demo-select-small"
                                id="demo-select-small"
                                value={retweetDelay}
                                label="Retweet Delay"
                                onChange={(e) => setRetweetDelay(e.target.value)}
                            >
                                <MenuItem value={0}>
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value={100}>100 liikes</MenuItem>
                                <MenuItem value={200}>200 liikes</MenuItem>
                                <MenuItem value={300}>300 liikes</MenuItem>
                                <MenuItem value={400}>400 liikes</MenuItem>
                                <MenuItem value={500}>500 liikes</MenuItem>
                                <MenuItem value={1000}>1000 liikes</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid> */}
                </Grid>
                <br />
                <br />
                <Button disabled={isScheduling} variant="contained" type="submit">
                    {isScheduling ? 'Scheduling...' : 'Schedule'}
                </Button>
            </form>
        </Box>
    );
};

export default TweetInputs;
