import React, { useState, createContext } from 'react';
import niche from 'views/tweetsy/nicheEngagement/engagementNiche';
import catagories from 'views/tweetsy/tweets/niche_list';

export const tweetContext = createContext();

const TweetProvider = ({ children }) => {
    // for Home page------------------
    const [homeTweets, setHomeTweets] = useState([]);
    const [pageHome, setPageHome] = useState({ page: 0, lastPage: null });
    const [searchText, setSearchText] = useState('');
    // for tweets page-----------------
    const [tweetsTweets, setTweetsTweets] = useState([]);
    const [pageTweets, setPageTweets] = useState({ page: 0, lastPage: null });
    const [tweetsNiche, setTweetsNiche] = useState();
    // const [tweetsNiche, setTweetsNiche] = useState(catagories[0].value);
    const [searchText1, setSearchText1] = useState('');
    // for Schedule page--------------
    const [idMemorized, setIdMemorized] = useState(1); // next id for schedule input
    const [scheduleInputs, setScheduleInputs] = useState([{ id: 0, text: '' }]);
    // for Modal --------------------
    const [isModalOpen, setIsModalOpen] = useState(false);
    // for Niche Engagement page-----------------
    const [engagementTweet, setEngagementTweet] = useState([]);
    const [pageEngagement, setPageEngagement] = useState({ page: 0, lastPage: null });
    const [engagementNiche, setEngagementNiche] = useState(niche[0].value);
    // for user Engagement page-----------------
    const [userEngagementTweet, setUserEngagementTweet] = useState([]);
    const [pageUserEngagement, setPageUserEngagement] = useState({ page: 0, lastPage: null });
    // for Queue
    const [queue, setQueue] = useState([]);

    const allContext = {
        // for Home
        homeTweets,
        setHomeTweets,
        pageHome,
        setPageHome,
        searchText,
        setSearchText,
        // for Tweets
        tweetsTweets,
        setTweetsTweets,
        pageTweets,
        setPageTweets,
        tweetsNiche,
        setTweetsNiche,
        searchText1,
        setSearchText1,
        // for Schedule tweets
        idMemorized,
        setIdMemorized,
        scheduleInputs,
        setScheduleInputs,
        // for modal
        isModalOpen,
        setIsModalOpen,
        // for niche engagement
        engagementTweet,
        setEngagementTweet,
        pageEngagement,
        setPageEngagement,
        engagementNiche,
        setEngagementNiche,
        // TODO: for user engagement
        userEngagementTweet,
        setUserEngagementTweet,
        pageUserEngagement,
        setPageUserEngagement,
        // for queue
        queue,
        setQueue
    };

    return <tweetContext.Provider value={allContext}>{children}</tweetContext.Provider>;
};

export default TweetProvider;
