import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

const Unauthenticated = Loadable(lazy(() => import('views/tweetsy/unauthenticated')));

// tweetsy custom routing
// TODO: Remove all unnecessaru route.
// const Sample = Loadable(lazy(() => import('views/sample-page')));
const Analytics = Loadable(lazy(() => import('views/tweetsy/analytics')));
const AnalyticsTweets = Loadable(lazy(() => import('views/tweetsy/analyticsTweets')));
// const Dashboard = Loadable(lazy(() => import('views/tweetsy/dashboard')));
const Tweets = Loadable(lazy(() => import('views/tweetsy/tweets')));
const Queue = Loadable(lazy(() => import('views/tweetsy/queue')));
// const WelcomeMessage = Loadable(lazy(() => import('views/tweetsy/welcomeMessage')));
const Engagement = Loadable(lazy(() => import('views/tweetsy/engagement')));
const NicheEngagement = Loadable(lazy(() => import('views/tweetsy/nicheEngagement')));
// const ByKeyword = Loadable(lazy(() => import('views/tweetsy/by-keyword')));
const EngagementSetting = Loadable(lazy(() => import('views/tweetsy/engagementSettings')));
const Subscription = Loadable(lazy(() => import('views/tweetsy/subscription')));
const Settings = Loadable(lazy(() => import('views/tweetsy/Settings')));
const Expired = Loadable(lazy(() => import('views/tweetsy/expired')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        // {
        //     path: '/dashboard',
        //     element: <Dashboard />
        // },
        {
            path: '/dashboard',
            element: <Analytics />
        },
        {
            path: '/dashboard/tweets',
            element: <AnalyticsTweets />
        },
        {
            path: '/tweets',
            element: <Tweets />
        },
        {
            path: '/queue',
            element: <Queue />
        },
        // {
        //     path: '/welcome',
        //     element: <WelcomeMessage />
        // },
        {
            path: '/engagement',
            element: <Engagement />
        },
        {
            path: '/niche-engagement',
            element: <NicheEngagement />
        },
        // {
        //     path: '/keyword',
        //     element: <ByKeyword />
        // },
        {
            path: '/subscription',
            element: <Subscription />
        },
        {
            path: '/settings',
            element: <Settings />
        },
        {
            path: '/engagement/setting',
            element: <EngagementSetting />
        },
        {
            path: '/expired',
            element: <Expired />
        },
        {
            path: '/unauthenticated',
            element: <Unauthenticated />
        }
    ]
};

export default MainRoutes;
