import { lazy } from 'react';
// project imports
import NotFound from 'layout/NotFound';
import Loadable from 'ui-component/Loadable';

// TODO: Remove all unnecessaru route.
const Error = Loadable(lazy(() => import('views/pages/maintenance/Error')));

export default {
    path: '/',
    element: <NotFound />,
    children: [
        {
            path: '*',
            element: <Error />
        }
    ]
};
