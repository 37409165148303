const niche = [
    {
        name: 'SaaS',
        value: 'SaaS'
    },
    {
        name: 'Business',
        value: 'Business'
    },
    {
        name: 'Influencer',
        value: 'Influencer'
    },
    {
        name: 'Python',
        value: 'Python'
    },
    {
        name: 'Tech',
        value: 'Tech'
    },
    {
        name: 'NoCode',
        value: 'NoCode'
    },
    {
        name: 'DataScience',
        value: 'DataScience'
    },
    {
        name: 'Health',
        value: 'Health'
    },
    {
        name: 'Founder',
        value: 'Founder'
    },
    {
        name: 'Finance',
        value: 'Finance'
    }
];
export default niche;
